<div class="content" id="comparativo">
  <div class="content-heading">
    <button style="float: right" mat-stroked-button routerLink="/buscar-acoes">
      Analisar outra empresa
    </button>
    <div class="title">Comparativo</div>
    <div class="subtitle">Comparativos entre as ações já analisadas</div>
  </div>

  <div *ngIf="acoes.length == 0 && loading == false" class="proposta-card">
    <section class="example-section">
      <h4 style="font-weight: 600">Você ainda não analisou nenhuma ação.</h4>
      <button mat-stroked-button color="accent" [routerLink]="['/']">
        Analisar
      </button>
    </section>
  </div>
  <div *ngIf="acoes.length > 0" class="proposta-card">
    <section class="example-section">
      <mat-checkbox *ngFor="let acao of acoes" check-indeterminate="true" [(ngModel)]="acao.active"
        class="example-margin">{{ acao.data.ticker }}</mat-checkbox>
    </section>
  </div>
  <div class="alert alert-info" style="margin-top: 12px" role="alert" *ngIf="selectedCount > 4">
    Recomendamos que você só selecione 4 ações de uma vez.
  </div>
  <div *ngIf="acoes.length > 0" class="row" style="position: relative; display: none">
    <div class="col" *ngFor="let chart of graficos">
      <div class="proposta-card" style="width: 100%">
        <div class="heading-internal" style="margin-bottom: 0px">
          <div class="heading-surface"></div>
          <h2 class="heading-content">Gráfico Comparativo</h2>
        </div>
        <div class="card-content">
          <div class="content-filters">
            <span> Indicador </span>
            <a [matMenuTriggerFor]="menu" class="button-filter" mat-stroked-button>
              {{ chart.type | typeOfChart }}
              <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.5 4.60001L0.602887 0.600006L8.39711 0.600007L4.5 4.60001Z" fill="#109CF1" />
              </svg>
            </a>

            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="chart.type = false; setType(chart, 'rol')">
                <span>Receita Op. Líquida </span>
              </button>

              <button mat-menu-item (click)="chart.type = false; setType(chart, 'ebitda')">
                <span> Lucro Operacional</span>
              </button>

              <button mat-menu-item (click)="chart.type = false; setType(chart, 'lpa')">
                <span> Lucro por Ação</span>
              </button>

              <button mat-menu-item (click)="chart.type = false; setType(chart, 'dpa')">
                <span> Dividendos por Ação</span>
              </button>

              <button mat-menu-item (click)="
                  chart.type = false; setType(chart, 'patrimonioLiquido')
                ">
                <span> Patrimônio Líquido</span>
              </button>
            </mat-menu>
          </div>
          <app-lines-chart *ngIf="chart.type" [type]="chart.type" [data]="acoes"></app-lines-chart>
        </div>
      </div>
    </div>
    <div style="position: absolute; right: 36px; top: 48px; z-index: 1000">
      <button mat-mini-fab color="accent" (click)="graficos.push({ type: 'rol' })"
        aria-label="Example icon button with a plus one icon">
        <mat-icon>plus_one</mat-icon>
      </button>
    </div>
  </div>

  <div class="proposta-card" *ngIf="acoes.length > 0">
    <div class="proposta-card-content" style="width: 100%; overflow: auto !important">
      <div class="row" style="min-width: 620px">
        <div class="col-md-4" style="margin: 0px; padding: 0px; padding-left: 15px">
          <table class="table table-acao">
            <thead>
              <tr>
                <th class="td-left" scope="col">Indicador</th>
              </tr>
            </thead>
            <tbody>
              <tr style="background-color: #efefef">
                <th class="td-left td-left-cinza">Análise Receita Líquida</th>
              </tr>
              <tr style="background-color: #efefef">
                <th class="td-left td-left-cinza">Cresc. Receita Líquida</th>
              </tr>
              <tr style="background-color: #fff">
                <th class="td-left td-left-cinza">
                  Análise Margem Operacional (EBITDA)
                </th>
              </tr>

              <tr style="background-color: #fff">
                <th class="td-left td-left-cinza">
                  Análise Margem Líquida
                </th>
              </tr>
              <tr style="background-color: #fff">
                <th class="td-left td-left-cinza">Cresc. EBITDA</th>
              </tr>

              <tr style="background-color: #efefef">
                <th class="td-left td-left-cinza">Cresc. Lucro por Ação</th>
              </tr>

              <tr style="background-color: #efefef">
                <th class="td-left td-left-cinza">
                  Análise Dividendo por Ação
                </th>
              </tr>

              <tr style="background-color: #fff">
                <th class="td-left td-left-cinza">
                  ROE Atual (retorno s/ pat.)
                </th>
              </tr>
              <tr style="background-color: #fff">
                <th class="td-left td-left-cinza">Análise ROE</th>
              </tr>

              <tr style="background-color: #ffeed3">
                <th class="td-left td-left-yellow">P/L Histórico</th>
              </tr>
              <tr style="background-color: #fff">
                <th class="td-left td-left-yellow">P/L Médio Atual</th>
              </tr>

              <tr style="background-color: #eaf6ff">
                <th class="td-left td-left-blue">Payout</th>
              </tr>
              <tr style="background-color: #fff">
                <th class="td-left td-left-blue">Dividend Yield Histórico</th>
              </tr>

              <tr style="background-color: #eaf6ff">
                <th class="td-left td-left-blue">Dividend Yield Atual</th>
              </tr>
              <tr style="background-color: #efefef">
                <th class="td-left td-left-black">Resultado da Avaliação</th>
              </tr>
              <tr style="background-color: #fff">
                <th class="td-left td-left-black">Índice ED de Confirmação</th>
              </tr>
              <tr style="background-color: #efefef">
                <th class="td-left td-left-black">
                  Rentabilidade Anual Esperada
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col" style="overflow: auto; margin: 0px; padding: 0px">
          <table class="table table-acao">
            <thead>
              <tr>
                <ng-container *ngFor="let acao of acoes | activeTrue">
                  <th [routerLink]="['/acao', acao.data.ticker]" style="cursor: pointer" *ngIf="acao.active"
                    scope="col">
                    {{ acao.data.ticker }}
                  </th>
                </ng-container>
                <th class="complementa"></th>
              </tr>
            </thead>
            <tbody>
              <tr style="background-color: #efefef">
                <ng-container *ngFor="let acao of acoes | activeTrue">
                  <td (click)="toogleField($event)" *ngIf="acao.active" scope="col">
                    {{ acao.data.analiseRetrospectiva.rol | analiseText }}
                  </td>
                </ng-container>
                <td class="complementa"></td>
              </tr>
              <tr style="background-color: #efefef">
                <ng-container *ngFor="let acao of acoes | activeTrue">
                  <td (click)="toogleField($event)" *ngIf="acao.active" [ngClass]="{
                      green: acao.mediaRol > 0,
                      red: acao.mediaRol < 0
                    }">
                    {{ acao.mediaRol | percent }}
                  </td>
                </ng-container>
                <td class="complementa"></td>
              </tr>

              <tr>
                <ng-container *ngFor="let acao of acoes | activeTrue">
                  <td (click)="toogleField($event)" *ngIf="acao.active" scope="col">
                    {{
                      acao.data.analiseRetrospectiva.lucroOperacional
                        | analiseText
                    }}
                  </td>
                </ng-container>
                <td class="complementa"></td>
              </tr>
              <tr>
                <ng-container *ngFor="let acao of acoes | activeTrue">
                  <td (click)="toogleField($event)" *ngIf="acao.active" scope="col">
                    {{
                                    acao.data.analiseRetrospectiva.lpa
                                      | analiseText
                                  }}
                  </td>
                </ng-container>
                <td class="complementa"></td>
              </tr>
              <tr>
                <ng-container *ngFor="let acao of acoes | activeTrue">
                  <td (click)="toogleField($event)" *ngIf="acao.active" scope="col">
                    {{ acao.mediaEbitda | percent }}
                  </td>
                </ng-container>
                <td class="complementa"></td>
              </tr>

              <tr style="background-color: #efefef">
                <ng-container *ngFor="let acao of acoes | activeTrue">
                  <td (click)="toogleField($event)" *ngIf="acao.active" scope="col" [ngClass]="{
                      green: acao.mediaLpa > 0,
                      red: acao.mediaLpa < 0
                    }">
                    {{ acao.mediaLpa | percent }}
                  </td>
                </ng-container>
                <td class="complementa"></td>
              </tr>
              <tr style="background-color: #efefef">
                <ng-container *ngFor="let acao of acoes | activeTrue">
                  <td (click)="toogleField($event)" *ngIf="acao.active" scope="col">
                    {{ acao.data.analiseRetrospectiva.dpa | analiseText }}
                  </td>
                </ng-container>
                <td class="complementa"></td>
              </tr>
              <tr>
                <ng-container *ngFor="let acao of acoes | activeTrue">
                  <td (click)="toogleField($event)" *ngIf="acao.active" scope="col" [ngClass]="{
                      green: acao.mediaRoeValue > 0,
                      red: acao.mediaRoeValue < 0
                    }">
                    {{ acao.mediaRoeValue * 100 | percent }}
                  </td>
                </ng-container>
                <td class="complementa"></td>
              </tr>
              <tr style="background-color: #fff">
                <ng-container *ngFor="let acao of acoes | activeTrue">
                  <td (click)="toogleField($event)" *ngIf="acao.active" scope="col">
                    {{
                      acao.data.analiseRetrospectiva.patrimonioLiquido
                        | analiseText
                    }}
                  </td>
                </ng-container>
                <td class="complementa"></td>
              </tr>
              <tr style="background-color: #ffeed3">
                <ng-container *ngFor="let acao of acoes | activeTrue">
                  <td (click)="toogleField($event)" *ngIf="acao.active" scope="col">
                    {{ acao.referenciaIpl | number: "1.1-1" }} Anos
                  </td>
                </ng-container>
                <td class="complementa"></td>
              </tr>
              <tr style="background-color: #fff">
                <ng-container *ngFor="let acao of acoes | activeTrue">
                  <td (click)="toogleField($event)" *ngIf="acao.active" scope="col">
                    {{ acao.data.cotacao.indicePrecoLucroAno | number: "1.1-1" }} Anos
                  </td>
                </ng-container>
                <td class="complementa"></td>
              </tr>
              <tr style="background-color: #eaf6ff">
                <ng-container *ngFor="let acao of acoes | activeTrue">
                  <td (click)="toogleField($event)" *ngIf="acao.active" scope="col" style="font-weight: bold;"
                    [ngClass]="{
                      green: acao.mediaPayout > 0,
                      red: acao.mediaPayout < 0
                    }">{{ acao.mediaPayout | percent }}
                  </td>
                </ng-container>
                <td class="complementa"></td>
              </tr>

              <tr>
                <ng-container *ngFor="let acao of acoes | activeTrue">
                  <td (click)="toogleField($event)" *ngIf="acao.active" scope="col" [ngClass]="{
                      green: acao.referenciaYield > 0,
                      red: acao.referenciaYield < 0
                    }">
                    {{ acao.referenciaYield | percent }}
                  </td>
                </ng-container>
                <td class="complementa"></td>
              </tr>
              <tr style="background-color: #eaf6ff">
                <ng-container *ngFor="let acao of acoes | activeTrue">
                  <td (click)="toogleField($event)" *ngIf="acao.active" scope="col" [ngClass]="{
                      green: acao.lastYield > 0,
                      red: acao.lastYield < 0
                    }">
                    {{ acao.lastYield | percent }}
                  </td>
                </ng-container>
                <td class="complementa"></td>
              </tr>
              <tr style="background-color: #efefef">
                <ng-container *ngFor="let acao of acoes | activeTrue">
                  <td (click)="toogleField($event)" *ngIf="acao.active" style="font-weight: bold" scope="col">
                    {{ acao.data.analise.action }}
                  </td>
                </ng-container>
                <td class="complementa"></td>
              </tr>
              <tr style="background-color: #fff">
                <ng-container *ngFor="let acao of acoes | activeTrue">
                  <td (click)="toogleField($event)" *ngIf="acao.active" [ngClass]="{
                      'color-green': acao.data.analise.indiceED > 3,
                      'color-blue': acao.data.analise.indiceED > 2,
                      'color-red': acao.data.analise.indiceED <= 2
                    }">
                    {{ acao.data.analise.indiceED | number: "1.0-2" }}
                  </td>
                </ng-container>
                <td class="complementa"></td>
              </tr>
              <tr style="background-color: #efefef">
                <ng-container *ngFor="let acao of acoes | activeTrue">
                  <td (click)="toogleField($event)" *ngIf="acao.active" scope="col" [ngClass]="{
                      green: acao.data.analise.ganhoPotencial > 0,
                      red: acao.data.analise.ganhoPotencial < 0
                    }">
                    {{ acao.data.analise.ganhoPotencial | percent }}
                  </td>
                </ng-container>
                <td class="complementa"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
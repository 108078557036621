<div style="display: block; padding: 24px">
  <canvas
    baseChart
    style="width: 100%"
    height="280"
    width="800"
    [datasets]="lineChartData"
    [labels]="lineChartLabels"
    [options]="lineChartOptions"
    [colors]="lineChartColors"
    [legend]="lineChartLegend"
    [chartType]="lineChartType"
    [plugins]="lineChartPlugins"
  >
  </canvas>
</div>

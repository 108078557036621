import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeDuplicates',
})
export class RemoveDuplicatesPipe implements PipeTransform {
  transform(value: Array<any>, ...args: unknown[]): Array<any> {
    return value
      .reduce((unique, item) => {
        return unique.map((data) => data.title).includes(item.title)
          ? unique
          : [...unique, item];
      }, [])
      .filter((d, i) => i < 6);
  }
}

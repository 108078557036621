import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthFolderService } from 'src/app/callback-folder/auth-folder.service';

@Injectable({
  providedIn: 'root',
})
export class BuscarAcoesService {
  constructor(
    public httpClient: HttpClient,
    public oidcSecurityService: OidcSecurityService,
    public authFolderService: AuthFolderService,
  ) {
  }
  get token() {
    if(this.authFolderService.authenticated()){
      return this.authFolderService.getAccess();
    }
    return this.oidcSecurityService.getAccessToken();
  }
  find(text?: string, setor?: string, porte?: string, uc?: boolean) {
    let params: any = {};
    if (text != undefined && text.length > 0) {
      params.find = text;
    }

    if (setor != undefined && setor.length > 0) {
      params.setor = setor;
    }

    if (porte != undefined && porte.length > 0) {
      params.porte = porte;
    }
    if (uc != undefined) {
      params.uc = uc;
    }
    return this.httpClient.get(environment.url + 'indexer/actions', {
      params,
      headers: {
        Authorization: 'Bearer ' + this.token,
      },
    });
  }
}

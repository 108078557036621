export function convertDateYearMonth(string: any) {
  const date = new Date(string);
  return leftPad(date.getMonth() + 1, 2, '0') + '/' + date.getFullYear();
}
export function convertDateComplete(string: any) {
  const date = new Date(string);
  return (
    leftPad(date.getDate(), 2, '0') +
    '/' +
    leftPad(date.getMonth() + 1, 2, '0') +
    '/' +
    date.getFullYear()
  );
}
function leftPad(value, totalWidth, paddingChar) {
  var length = totalWidth - value.toString().length + 1;
  return Array(length).join(paddingChar || '0') + value;
}
export function formatarValor(
  valor: number,
  moeda: boolean = true,
  simboloMil: string = 'mil',
  simboloMilhao: string = 'mi',
  simboloBilhao: string = 'bi',
  maximoCasasDecimais: number = 2
) {
  if (valor) {
    const valorAbsoluto = Math.abs(valor);
    let divisor: number = 1.0;
    let simbolo: string = '';

    if (valorAbsoluto >= 1000000000.0) {
      divisor = 1000000000.0;
      simbolo = ' ' + simboloBilhao;
    } else if (valorAbsoluto >= 1000000.0) {
      divisor = 1000000.0;
      simbolo = ' ' + simboloMilhao;
    } else if (valorAbsoluto >= 1000.0) {
      divisor = 1000.0;
      simbolo = ' ' + simboloMil;
    }

    let estilo: Intl.NumberFormatOptions;
    if (moeda) {
      estilo = {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 1,
        maximumFractionDigits: 2,
      };
    } else {
      estilo = { style: 'decimal', maximumFractionDigits: maximoCasasDecimais };
    }

    let novoValor: number = valor / divisor;
    return `${novoValor.toLocaleString('pt-BR', estilo)}${simbolo}`;
  } else {
    return '-';
  }
}
export function adicionarData(data: Date, dias: number): Date {
  const milesimosSegundosDia = 1 * 24 * 60 * 60 * 1000; // dia * horas * minutos * segundos * milésimos
  const novaData = new Date(data.getTime());
  novaData.setTime(novaData.getTime() + dias * milesimosSegundosDia);
  return novaData;
}
export function filterName(name: string, value: any, i: number) {
  if (value == undefined) {
    return true;
  }
  if (value[name] != undefined) {
    if (value[name][i] == true) return false;
  }
  return true;
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthFolderService } from '../callback-folder/auth-folder.service';

@Injectable({
  providedIn: 'root',
})
export class AdminUsersService {
  data$ = new ReplaySubject();
  constructor(public httpClient: HttpClient, public oidcSecurityService: OidcSecurityService, public authFolderService: AuthFolderService,
  ) {
  }
  get token() {
    if (this.authFolderService.authenticated()) {
      return this.authFolderService.getAccess();
    }
    return this.oidcSecurityService.getAccessToken();
  }
  paginate(page: number, search: string) {
    return this.httpClient
      .get(environment.url + 'v1/users/admin/list', {
        params: {
          page: page.toString(),
          search: search,
        },
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      })
      .pipe(
        map((item) => {
          this.data$.next(item);
        })
      );
  }
  selectStatus(email: string, status: boolean) {
    return this.httpClient.put(
      environment.url + '/v1users/admin/update/selectus',
      {
        email,
        status,
      },
      {
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      }
    );
  }
}

<h2 style="font-weight: bold; margin-bottom: 0px">
  Seja bem-vindo ao Sistema Selectus!
</h2>
<iframe src="https://player.vimeo.com/video/486401542" width="100%" height="70%" frameborder="0"
  allow="autoplay; fullscreen" allowfullscreen></iframe>

<button style="float: right" mat-raised-button color="secondary" (click)="onNoClick()">
  Fechar
</button>
<a style="float: right; background: #2196f3; color: #fff; margin-right: 10px" mat-raised-button target="_blank"
  color="primary" href="https://empreenderdinheiro.com.br/checklist-metodo-ed/">
  Baixar Checklist
</a>
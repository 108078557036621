import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { patternValidator } from '../login/pattern-validator';
import { RecoveryPasswordService } from './recovery-password.service';

@Component({
    selector: 'app-recovery-password',
    templateUrl: './recovery-password.component.html',
    styleUrls: ['./recovery-password.component.scss']
})
export class RecoveryPasswordComponent implements OnInit {
    code: string;
    recoveryEmail = new FormGroup({
        email: new FormControl('', [
            Validators.required,
            patternValidator(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ),
        ]),
    });

    recoveryPassword = new FormGroup({
        password: new FormControl('', [
            Validators.required,
            Validators.minLength(6)
        ]),
        passwordConfirmation: new FormControl('', [
            Validators.required,
            Validators.minLength(6)
        ]),
    }, {
        validators: this.checkPasswords
    });
    checkPasswords(group: FormGroup) {
        console.log(group);
        let pass = group.get('password').value;
        let confirmPass = group.get('passwordConfirmation').value;
        if (confirmPass?.length < 6) {
            return null;
        }

        return pass === confirmPass ? null : { notSame: true }
    }
    constructor(
        public activeRoute: ActivatedRoute,
        public recoveryPasswordService: RecoveryPasswordService,
    ) {
        this.activeRoute.params.subscribe((data) => {
            console.log(data.code);
            this.code = data.code;
        });
    }

    ngOnInit() {
    }
    submit() {
        this.recoveryPasswordService.sendRecoveryPassowrd(this.recoveryEmail.value).subscribe(() => {
            alert('Confira seu e-mail e acesse o link enviado.');
        });
    }
    submitRecoveryPassword() {
        this.recoveryPasswordService.changePasswordWithCode(this.code, this.recoveryPassword.get('password').value).subscribe(() => {
            alert('Senha alterada com sucesso.');
            location.href = '/';
        });
    }

}

import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-select-analise',
  templateUrl: './select-analise.component.html',
  styleUrls: ['./select-analise.component.scss'],
})
export class SelectAnaliseComponent implements OnInit {
  @Input()
  control: FormControl;

  @Input('subtitle')
  subtitle: string;

  constructor() {}

  ngOnInit(): void {}
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yearMonth',
})
export class YearMonthPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    let date = new Date(value);
    return date.getFullYear().toString();
  }
}

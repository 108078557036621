import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class RecoveryPasswordService {
    constructor(public httpClient: HttpClient) { }
    sendRecoveryPassowrd(body: { email: string }) {
        return this.httpClient.put(
            environment.url + 'users/recoveryPassword',
            body
        );
    }
    changePasswordWithCode(restorePasswordCode: string, password: string) {
        return this.httpClient.put(
            environment.url + 'users/changePasswordRecovery',
            { restorePasswordCode, password, }
        );
    }
}

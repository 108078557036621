<div class="content">
  <div class="content-heading">
    <div style="margin-bottom: 0px" class="title">
      Administração de usuários
    </div>
    <div class="subtitle">
      Adicione e permita que usuários acessem a ferramenta.
    </div>
  </div>
  <div class="users-table">
    <input
      [formControl]="searchFormControl"
      type="text"
      placeholder="Clique aqui para buscar"
    />
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Nome Completo</th>
          <th scope="col">E-mail</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let user of users
              | paginate
                : {
                    id: 'server',
                    itemsPerPage: 10,
                    currentPage: page,
                    totalItems: count
                  }
          "
        >
          <td>{{ user.firstName }} {{ user.lastName }}</td>
          <td>{{ user.email }}</td>
          <td>
            <div
              class="status"
              *ngIf="user.selectusActive == true"
              (click)="setStatus(user.email, false)"
              style="color: green"
            >
              Sistema Selectus Ativo
              <button class="btn btn-primary">Desativar</button>
            </div>
            <div
              class="status"
              *ngIf="user.selectusActive != true"
              (click)="setStatus(user.email, true)"
              style="color: red"
            >
              Sistema Selectus Desativo
              <button class="btn btn-primary">Ativar</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination-controls
      id="server"
      [maxSize]="count"
      (pageChange)="setPage($event)"
    ></pagination-controls>
  </div>
</div>

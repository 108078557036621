import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'normalMoney',
})
export class NormalMoneyPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    return value.toFixed(2).replace('.', ',');
  }
}

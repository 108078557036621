import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'divida',
})
export class DividaPipe implements PipeTransform {
  transform(value: string, ...args: string[]): string {
    return value
      .replace(`Dívida `, ``)
      .replace(`Endividamento`, `end.`)
      .replace('que a Dívida', '')
      .replace('Patrimônio Líq. Negativo', 'Pat. Líq. Negativo');
  }
}

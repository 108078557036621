import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UploadPlanilhaService } from '../upload-planilha.service';

@Component({
  selector: 'app-upload-planilha',
  templateUrl: './upload-planilha.component.html',
  styleUrls: ['./upload-planilha.component.scss'],
})
export class UploadPlanilhaComponent implements OnInit {
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  file;
  error: any;
  constructor(public uploadPlanilha: UploadPlanilhaService) {}

  ngOnInit(): void {}
  onUpload(event) {
    this.file = event.target.files[0];
    console.log(event.target.files);
    console.log(this.fileUpload);
  }
  enviar() {
    console.log(this.file);
    alert(`Por favor aguarde.`);
    this.error = undefined;
    this.uploadPlanilha.upload(this.file).subscribe(
      (data) => {
        alert(`Atualizado com sucesso.`);
      },
      (error) => {
        this.error = error;
      }
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverseOne',
})
export class ReverseOnePipe implements PipeTransform {
  transform(value: Array<any>, ...args: unknown[]): Array<any> {
    return JSON.parse(JSON.stringify(value)).reverse();
  }
}

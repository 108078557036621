import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultModule } from './layouts/default/default.module';
import { BuscarAcoesComponent } from './modules/buscar-acoes/buscar-acoes.component';
import { LoginComponent } from './modules/login/login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { CadastroComponent } from './modules/cadastro/cadastro.component';
import { AuthGuard } from './shared/auth-guard.service';
import { HttpClientModule } from '@angular/common/http';
import { NormalMoneyPipe } from './shared/normal-money.pipe';
import { MilionMoneyPipe } from './shared/milion-money.pipe';
import { DividaPipe } from './shared/divida.pipe';
import { AcaoComponent } from './modules/acao/acao.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule, MatTab } from '@angular/material/tabs';
import { YearMonthPipe } from './modules/acao/year-month.pipe';
import { PercentPipe } from './modules/acao/percent.pipe';
import { RemoveDuplicatesPipe } from './modules/acao/remove-duplicates.pipe';
import { MoneyFullPipe } from './modules/acao/money-full.pipe';
import { ChartsModule } from 'ng2-charts';
import { LineChartComponent } from './modules/acao/line-chart/line-chart.component';
import { SelectAnaliseComponent } from './modules/acao/select-analise/select-analise.component';
import { ReverseOnePipe } from './modules/acao/reverse-one.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ComparativoComponent } from './comparativo/comparativo.component';
import { CallbackComponent } from './callback/callback.component';
import { AnaliseTextPipe } from './comparativo/analise-text.pipe';
import { ActiveTruePipe } from './comparativo/active-true.pipe';
import { NgxPaginationModule } from 'ngx-pagination';

import { LinesChartComponent } from './comparativo/lines-chart/line-chart.component';
import { MatMenuModule } from '@angular/material/menu';
import { TypeOfChartPipe } from './comparativo/type-of-chart.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModalStartComponent } from './modal-start/modal-start.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AcaoItemComponent } from './modules/acao/acao-item/acao-item.component';
import { AdminUsersComponent } from './modules/admin-users/admin-users.component';
import { UploadPlanilhaComponent } from './modules/upload-planilha/upload-planilha.component';
import { ItemActionComponent } from './shared/components/item-action/item-action.component';
import { RecoveryPasswordComponent } from './modules/recovery-password/recovery-password.component';
import { AuthModule } from 'angular-auth-oidc-client';
import { authConfig } from './auth.config'
import { CallbackFolderComponent } from './callback-folder/callback-folder.component';

@NgModule({
  declarations: [
    AppComponent,
    BuscarAcoesComponent,
    LoginComponent,
    CadastroComponent,
    NormalMoneyPipe,
    MilionMoneyPipe,
    DividaPipe,
    AcaoComponent,
    YearMonthPipe,
    PercentPipe,
    CallbackComponent,
    RemoveDuplicatesPipe,
    MoneyFullPipe,
    LineChartComponent,
    LinesChartComponent,
    SelectAnaliseComponent,
    ReverseOnePipe,
    ComparativoComponent,
    AnaliseTextPipe,
    ActiveTruePipe,
    TypeOfChartPipe,
    ModalStartComponent,
    AcaoItemComponent,
    AdminUsersComponent,
    UploadPlanilhaComponent,
    ItemActionComponent, RecoveryPasswordComponent,
    CallbackFolderComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ChartsModule,
    MatTooltipModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DefaultModule,
    MatDialogModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPaginationModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatChipsModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    AuthModule.forRoot({
      config: authConfig,
    }),
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent],
})
export class AppModule { }

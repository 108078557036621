import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-item-action',
  templateUrl: './item-action.component.html',
  styleUrls: ['./item-action.component.scss'],
})
export class ItemActionComponent implements OnInit {
  @Input('acao') acao: any;
  @Input('analisados') analisados: any;

  constructor() {}

  ngOnInit(): void {}
}

<mat-nav-list>

  <div *ngIf="activeFolder === true" style="
  padding-top: 24px;
  padding-left: 18px;
  margin-bottom: -20px;
">
    <button (click)="backFolder()" mat-stroked-button color="primary" style="border-radius: 30px; margin-bottom: 24px; color: #109cf1;">
      <svg style="width:24px;height:24px; float: left; margin-top: 5px; margin-right: 6px;" viewBox="0 0 24 24">
      <path fill="currentColor" d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
  </svg>
  Voltar para TechFinance
  </button>
  </div>
  <h2 matSubheader>
    Bem-Vindo! <br />
    <b>ao Sistema Selectus.</b>
  </h2>

  <a mat-list-item routerLinkActive="list-item-active" routerLink="/buscar-acoes">
    <mat-icon matListIcon>search</mat-icon>
    Universo de Cobertura
  </a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/comparativo">
    <mat-icon matListIcon>subject</mat-icon>
    Comparativo
  </a>
  <a mat-list-item href="https://vimeo.com/showcase/fabricademilionarios" target="_blank">
    <mat-icon matListIcon>book</mat-icon>
    Fabrica de Milionários
  </a>
  <a mat-list-item href="http://empreenderdinheiro.com.br/manual-tec" target="_blank">
    <mat-icon matListIcon>article</mat-icon>
    Manual Técnico
  </a>
  <a mat-list-item routerLink="/users" *ngIf="isAdmin" routerLinkActive="list-item-active">
    <mat-icon matListIcon>people</mat-icon>
    Administrar Usuários
  </a>

  <a mat-list-item routerLink="/planilha" *ngIf="isAdmin" routerLinkActive="list-item-active">
    <mat-icon matListIcon>subject</mat-icon>
    Atualizar Planilha
  </a>

  <a mat-list-item (click)="openModal()">
    <mat-icon matListIcon>help</mat-icon>
    Tutorial
  </a>

  <a *ngIf="origin=='empreenderdinheiro' && activeFolder === false" mat-list-item routerLinkActive="list-item-active"
    [href]="['https:/', 'investidor.' +  baseUrl , accessToken].join('/')">
    <mat-icon matListIcon>assignment_return</mat-icon>
    Retornar ao Investidor 5.0
  </a>
  <a *ngIf="origin=='techfinance'  && activeFolder === false" mat-list-item routerLinkActive="list-item-active"
    [href]="['https:/', 'techfinance.' +  baseUrl , accessToken].join('/')">
    <mat-icon matListIcon>assignment_return</mat-icon>
    Retornar à TechFinance
  </a>

  <div class="footer">
    Powered by Empreender Dinheiro
  </div>
</mat-nav-list>
import { filterName } from "../modules/acao/utils";

function average(nums) {
  if (nums == undefined) {
    return 0;
  }
  if (nums.length == 0) {
    return 0;
  }
  return nums.reduce((a, b) => a + b) / nums.length;
}
export class Acao {
  data: AcaoType;
  active: boolean = true;
  constructor(data) {
    this.data = this.mapperOne(data);
  }
  indicePrecoLucro() {
    this.mediaLpa / this.data.cotacao.atual;
  }
  get mediaRol() {
    return average(
      this.data.rol

        .filter((item, i) =>
          filterName('rolCrescimento', this.data.ignoreFields, i)
        )
        .filter((item: any) => item.crescimento != undefined)
        .map((item: any) => item.crescimento)
    );
  }

  get mediaEbitda() {
    function average(nums) {
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      this.data.ebitda
        .filter((item) => item.crescimento != undefined)
        .map((item) => item.crescimento)
    );
  }
  get mediaIPLMax() {
    console.log(
      'mediaIPLMax',
      this.data.ticker,
      this.data.cotacao.meses,
      'iplMax'
    );
    return average(
      this.data.cotacao.meses
        .filter(
          (item: any) =>
            item.iplMax != undefined && item.iplMax.toString() != 'NaN'
        )
        .map((item: any) => item.iplMax)
    );
  }
  get mediaIPLMin() {
    return average(
      this.data.cotacao.meses
        .filter(
          (item: any) =>
            item.iplMin != undefined && item.iplMin.toString() != 'NaN'
        )
        .map((item: any) => item.iplMin)
    );
  }
  get referenciaIpl() {
    function average(nums) {
      if (nums == undefined) {
        return 0;
      }
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average([this.mediaIPLMax, this.mediaIPLMin]);
  }
  get mediaRoeValue() {
    function average(nums) {
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      this.data.roe
        .filter((item) => item.value != undefined)
        .map((item) => item.value)
    );
  }

  get mediaYieldMax() {
    function average(nums) {
      if (nums == undefined) {
        return 0;
      }
      if (nums.length == 0) {
        return 0;
      }
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      this.data.cotacao.meses
        .filter(
          (item: any) =>
            item.yieldMax != undefined && item.yieldMax.toString() != 'NaN'
        )
        .map((item: any) => item.yieldMax)
    );
  }
  get mediaYieldMin() {
    function average(nums) {
      if (nums == undefined) {
        return 0;
      }
      if (nums.length == 0) {
        return 0;
      }
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      this.data.cotacao.meses
        .filter(
          (item: any) =>
            item.yieldMin != undefined && item.yieldMin.toString() != 'NaN'
        )
        .map((item: any) => item.yieldMin)
    );
  }
  get referenciaYield() {
    function average(nums) {
      if (nums == undefined) {
        return 0;
      }
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average([this.mediaYieldMax, this.mediaYieldMin]);
  }
  get lastYield() {
    return (
      ((this.data.dpa[0].value *
        (this.data.cotacao.atual /
          this.data.cotacao.lpaAtual /
          this.data.cotacao.indicePrecoLucroAno)) /
        this.data.cotacao.atual) *
      100
    );
  }
  get mediaRoe() {
    function average(nums) {
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      this.data.roe
        .filter((item) => item.crescimento != undefined)
        .map((item) => item.crescimento)
    );
  }
  get mediaLpa() {
    return average(
      this.data.lpa
        .filter((item: any) => item.crescimento != undefined)
        .map((item: any) => item.crescimento)
    );
  }
  get mediaPayout() {
    return average(
      this.data.cotacao.meses
        .filter(
          (item: any) =>
            item.payout != undefined && item.payout.toString() != 'NaN'
        )
        .map((item: any) => item.payout)
    );
  }

  mapperOne(acao: any) {
    acao.booleans = {};
    acao.booleans.ebitda = acao.ebitda[0].value != null;
    acao.booleans.rol = acao.rol[0].value != null;
    acao.booleans.dpa = acao.dpa[0].value != null;
    acao.booleans.lpa = acao.lpa[0].value != null;
    acao.booleans.margemLiquida = acao.margemLiquida[0].value != null;

    acao.ebitda = acao.ebitda.map((item, i) => {
      item.margem = (item.value / acao.rol[i].value) * 100;
      return item;
    });
    acao.cotacao.meses = acao.cotacao.meses
      .map((item, i) => {
        let lpa = acao.lpa[i];
        if (lpa == undefined) {
          return item;
        }
        item.iplMax = item.max / lpa.value;
        item.iplMin = item.min / lpa.value;
        return item;
      })
      .map((item, i) => {
        let dpa = acao.dpa[i];
        if (dpa == undefined) {
          return item;
        }
        console.log(acao);
        item.yieldMax =
          ((dpa.value *
            (acao.cotacao.atual /
              acao.cotacao.lpaAtual /
              acao.cotacao.indicePrecoLucroAno)) /
            item.min) *
          100;
        item.yieldMin =
          ((dpa.value *
            (acao.cotacao.atual /
              acao.cotacao.lpaAtual /
              acao.cotacao.indicePrecoLucroAno)) /
            item.max) *
          100;
        return item;
      })
      .map((item, i) => {
        let dpa = acao.dpa[i];
        let lpa = acao.lpa[i];
        if (dpa == undefined) {
          item.payout = 0;
          return item;
        }
        if (lpa == undefined) {
          item.payout = 0;

          return item;
        }
        item.payout = (dpa.value / lpa.value) * 100;
        return item;
      })
      .filter((item) => item.max != undefined);
    return acao;
  }
}
export interface AcaoType {
  pvpa: Pvpa[];
  ignoreFields: any;
  patrimonioLiquido: PatrimonioLiquido[];
  roe: Pvpa[];
  dpa: Pvpa[];
  lucroOperacional: PatrimonioLiquido[];
  ebitda: PatrimonioLiquido[];
  rol: PatrimonioLiquido[];
  rop: Rop[];
  margemLiquida: PatrimonioLiquido[];
  lpa: Lpa[];
  rif: Rop[];
  _id: string;
  ticker: string;
  name: string;
  dataUltimaCotacao: string;
  dataUltimoBalanco: string;
  dataIpo: string;
  formatoBalanco: string;
  classe: string;
  setor: string;
  setor_tag: string;
  participacao: Participacao;
  cotacao: any;
  valuation: number;
  valorMkt: number;
  porte: string;
  empreenderDinheiro: EmpreenderDinheiro;
  formula: Formula;
  __v: number;
}

interface Formula {
  B: string;
  C: string;
  D: string;
  E: string;
  F: string;
  G: string;
  H: string;
  I: string;
  J: string;
  K: string;
  L: number;
  M: number;
  Q: number;
  R: number;
  S: number;
  T: number;
  U: number;
  V: string;
  X: number;
  Y: number;
  Z: number;
  AA: number;
  AB: number;
  AC: number;
  AD: number;
  AE: number;
  AF: number;
  AG: number;
  AM: number;
  AO: number;
  AP: number;
  AQ: number;
  AR: number;
  AS: number;
  AT: number;
  BP: number;
  BQ: number;
  BR: number;
  BS: number;
  BT: number;
  BU: number;
  BY: number;
  BZ: number;
  CA: number;
  CB: number;
  CC: number;
  CD: number;
  CH: string;
  CI: string;
  CJ: string;
  CK: string;
  CM: string;
  CN: string;
  CO: string;
  CP: string;
  CS: number;
  CT: number;
  CU: number;
  CV: number;
  CW: number;
  CX: number;
  CY: number;
  DC: number;
  DD: number;
  DE: number;
  DF: number;
  DG: number;
  DH: number;
  DL: number;
  DM: number;
  DN: number;
  DO: number;
  DU: number;
  DV: number;
  DW: number;
  DX: number;
  DY: number;
  ED: number;
  EE: number;
  EF: number;
  EG: number;
  EH: number;
  EI: number;
  EM: number;
  EN: number;
  EO: number;
  EP: number;
  EQ: number;
  FG: string;
  FH: string;
  FI: string;
  FJ: string;
}

interface EmpreenderDinheiro {
  divida: string;
  tagAlong: string;
  governanca: string;
  tagAlongPorcentagem: string;
}

interface Cotacao {
  atual: number;
  ultimos12Meses: Ultimos12Meses;
  meses: Mese[];
  indicePrecoLucroAno: number;
}

interface Mese {
  title: string;
  ultimo?: boolean;
  max?: number;
  min?: number;
  crescimento: number;
}

interface Ultimos12Meses {
  max: number;
  min: number;
}

interface Participacao {
  ibovespa: number;
  ibrx: number;
  smll: number;
  idiv: number;
}

interface Lpa {
  title: string;
  value: number;
  crescimento: number;
}

interface Rop {
  title: string;
  ultimo?: boolean;
  crescimento: number;
}

interface PatrimonioLiquido {
  title: string;
  ultimo?: boolean;
  value: number;
  crescimento: number;
}

interface Pvpa {
  title: string;
  ultimo?: boolean;
  value?: number;
  crescimento: number;
}

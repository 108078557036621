import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthFolderService {
  constructor() { }
  setAccess(token: string) {
    localStorage.folderToken = token;
  }
  getAccess(): string {
    return localStorage.folderToken;
  }
  authenticated(): boolean {
    return localStorage.folderToken !== undefined;
  }
}

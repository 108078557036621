import { Component, OnInit, HostListener } from '@angular/core';
import { AcaoService } from './acao.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { filterName } from './utils';

@Component({
  selector: 'app-acao',
  templateUrl: './acao.component.html',
  styleUrls: ['./acao.component.scss'],
})
export class AcaoComponent implements OnInit {
  loading = true;
  finalizado = false;
  analise: any;
  data: any = {};
  date = new Date();
  activeTabs = {
    lucroOperacional: 'table',
    rol: 'table',
    dpa: 'table',
    lpa: 'table',
    patrimonioLiquido: 'table',
  };
  tratamentoEspecial: any = {
    LWSA3:
      'Atenção: essa empresa fez IPO recente, por isso, é comum não apresentar alguns dados históricos.',
    MTRE3:
      'Atenção: essa empresa fez IPO recente, por isso, é comum não apresentar alguns dados históricos.',
    CNTO3: 'Atenção: essa empresa fez IPO recente, por isso, é comum não apresentar alguns dados históricos.',
    VIVA3:
      'Atenção: essa empresa fez IPO recente, por isso, é comum não apresentar alguns dados históricos.',
    ALPK3:
      'Atenção: essa empresa fez IPO recente, por isso, é comum não apresentar alguns dados históricos.',
    LAVV3:
      'Atenção: essa empresa fez IPO recente, por isso, é comum não apresentar alguns dados históricos.',
    AMBP3:
      'Atenção: essa empresa fez IPO recente, por isso, é comum não apresentar alguns dados históricos.',
    LJKK3:
      'Atenção: essa empresa fez IPO recente, por isso, é comum não apresentar alguns dados históricos.',
    'UNITS ': 'Termina com 11. Ex. EMPD11',
  };
  analiseRetrospectiva: FormGroup = new FormGroup({
    lucroOperacional: new FormControl(null, [Validators.required]),
    rol: new FormControl(null, [Validators.required]),
    lpa: new FormControl(null, [Validators.required]),
    dpa: new FormControl(null, [Validators.required]),
    roe: new FormControl(null, []),
    patrimonioLiquido: new FormControl(null, [Validators.required]),
  });
  estimativasPorcentagem: FormGroup = new FormGroup({
    rol: new FormControl(5, [Validators.required]),
    lpa: new FormControl(5, [Validators.required]),
    payout: new FormControl(25, [Validators.required]),
  });
  ignoreField = new FormControl({});
  prospectData: any = {};
  estimativaData: any = {};
  isScrolled = false;
  selic: any;

  constructor(public acaoService: AcaoService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.acaoService.findOne(params['ticker']).subscribe((data) => {
        this.data = data;
        console.log('acao', data);
        if (this.data.analisada != null) {
          this.analiseRetrospectiva.setValue(
            this.data.analisada.analiseRetrospectiva
          );
          this.estimativasPorcentagem.setValue(
            this.data.analisada.estimativasPorcentagem
          );

          console.log('ignoreFilter', this.data.analisada);
          if (this.data.analisada.ignoreFields != null) this.ignoreField.setValue(this.data.analisada.ignoreFields);
          this.finaliza();
        }
        this.loading = false;
        this.acaoService.ignoreFilter$.subscribe((filters) => {
          this.loadProspectData();
        });

        this.acaoService.ignoreFilter$.next([]);
      });
    });
    this.ignoreField.valueChanges.subscribe((value) => {
      this.loadProspectData();
    });
    this.estimativasPorcentagem.valueChanges.subscribe((value) => {
      this.loadProspectData();
    });
    document
      .getElementsByClassName('mat-drawer-content')[0]
      .addEventListener('scroll', (e) => {
        if (window.screen.width < 620) {
          return;
        }
        if (
          document.getElementsByClassName('mat-drawer-content')[0].scrollTop >
          200
        ) {
          this.isScrolled = true;
          return;
        }

        this.isScrolled = false;
      });
  }
  // Valor da taxa selic atual é de 13,25% verificar o site https://www.bcb.gov.br/estatisticas/grafico/graficoestatistica/metaselic
  indicePrecoLucroTesouro() {
    return 9.1
  }
  loadProspectData() {
    this.prospectData = {
      mediaPayout: this.mediaPayout,
      mediaLpa: this.mediaLpaCrescimento,
      mediaRol: this.mediaRol,
      ultimaRol: this.data.rol
        .reduce((v, a) => {
          v.unshift(a);
          return v;
        }, [])
        .reduce((curr, item) => {
          if (item.value != undefined) {
            return item.value;
          }
          return curr;
        }, 0),
      ultimaLpa: this.data.lpa
        .reduce((v, a) => {
          v.unshift(a);
          return v;
        }, [])
        .reduce((curr, item) => {
          if (item.value != undefined) {
            return item.value;
          }
          return curr;
        }, 0),
      ultimaDpa: this.data.dpa
        .reduce((v, a) => {
          v.unshift(a);
          return v;
        }, [])
        .reduce((curr, item) => {
          if (item.value != undefined) {
            return item.value;
          }
          return curr;
        }, 0),
    };
    console.log(this.prospectData);
    let rolMediaProjecao = this.estimativasPorcentagem.get('rol').value
      ? this.estimativasPorcentagem.get('rol').value
      : this.mediaLpa;
    let lpaMediaProjecao = this.estimativasPorcentagem.get('lpa').value
      ? this.estimativasPorcentagem.get('lpa').value
      : this.mediaLpa;
    let payoutMediaProjecao = this.estimativasPorcentagem.get('payout').value
      ? this.estimativasPorcentagem.get('payout').value
      : this.mediaPayout;
    const dpaList = this.generateValues(
      lpaMediaProjecao,
      this.prospectData.ultimaLpa
    ).map((dpa) => {
      dpa.value = (dpa.value * payoutMediaProjecao) / 100;
      return dpa;
    });
    this.estimativaData = {
      rol: this.generateValues(rolMediaProjecao, this.prospectData.ultimaRol),
      lpa: this.generateValues(lpaMediaProjecao, this.prospectData.ultimaLpa),
      dpa: dpaList,
      mediaDpa: this.mediaDpa,
      mediaDpaProjetada: this.mediaCalc(dpaList),
      cotacao: this.generateValues(
        lpaMediaProjecao,
        this.prospectData.ultimaLpa
      ).map((item: any, key: number) => {
        console.log('cotacao', this.mediaIPLMax);
        item.max =
          this.mediaIPLMax *
          1.2 *
          item.value *
          (this.data.cotacao.atual /
            this.data.cotacao.lpaAtual /
            this.data.cotacao.indicePrecoLucroAno);
        item.min =
          this.mediaIPLMin *
          item.value *
          (this.data.cotacao.atual /
            this.data.cotacao.lpaAtual /
            this.data.cotacao.indicePrecoLucroAno);
        return item;
      }),
    };
  }
  generateValues(percent: number, start: number) {
    var d = new Date();
    percent = (percent + 100) / 100;
    return [
      {
        title: new Date(d.getFullYear() + 1, d.getMonth(), d.getDate()),
        value: start * percent,
      },
      {
        title: new Date(d.getFullYear() + 2, d.getMonth(), d.getDate()),

        value: start * percent * percent,
      },
      {
        title: new Date(d.getFullYear() + 3, d.getMonth(), d.getDate()),

        value: start * percent * percent * percent,
      },
      {
        title: new Date(d.getFullYear() + 4, d.getMonth(), d.getDate()),

        value: start * percent * percent * percent * percent,
      },
      {
        title: new Date(d.getFullYear() + 5, d.getMonth(), d.getDate()),

        value: start * percent * percent * percent * percent * percent,
      },
    ];
  }
  selectTab(event, name) {
    this.activeTabs[name] = event == 0 ? 'table' : 'chart';
  }
  get mediaRol() {
    function average(nums) {
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      this.data.rol
        .filter((item, i) =>
          filterName('rolCrescimento', this.ignoreField.value, i)
        )
        .filter((item) => item.crescimento != undefined)
        .map((item) => item.crescimento)
    );
  }
  get mediaRoeValue() {
    function average(nums) {
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      this.data.roe
        .filter((item, i) => filterName('roe', this.ignoreField.value, i))
        .filter((item) => item.value != undefined)
        .map((item) => item.value)
    );
  }
  get mediaRoe() {
    function average(nums) {
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      this.data.roe
        .filter((item, i) => filterName('roe', this.ignoreField.value, i))
        .filter((item) => item.crescimento != undefined)
        .map((item) => item.crescimento)
    );
  }
  get mediaEbitda() {
    function average(nums) {
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      this.data.ebitda
        .filter((item, i) =>
          filterName('ebitdaCrescimento', this.ignoreField.value, i)
        )
        .filter((item) => item.crescimento != undefined)
        .map((item) => item.crescimento)
    );
  }
  get mediaMargemEbitda() {
    function average(nums) {
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      this.data.ebitda
        .filter((item, i) =>
          filterName('margemEbtida', this.ignoreField.value, i)
        )
        .filter((item) => item.margem != undefined)

        .filter((item) => item.margem.toString() != 'NaN')

        .map((item) => item.margem)
    );
  }
  get mediaMargemLiquida() {
    function average(nums) {
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      this.data.margemLiquida

        .filter((item, i) =>
          filterName('margemLiquida', this.ignoreField.value, i)
        )
        .filter((item) => item.value != undefined)
        .map((item) => item.value)
    );
  }
  get mediaIPLMax() {
    function average(nums) {
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      this.data.cotacao.meses

        .filter((item, i) => filterName('iplMax', this.ignoreField.value, i))
        .filter(
          (item) => item.iplMax != undefined && item.iplMax.toString() != 'NaN'
        )
        .map((item) => item.iplMax)
    );
  }
  get mediaIPLMin() {
    function average(nums) {
      if (nums == undefined) {
        return 0;
      }
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      this.data.cotacao.meses

        .filter((item, i) => filterName('iplMin', this.ignoreField.value, i))
        .filter(
          (item) => item.iplMin != undefined && item.iplMin.toString() != 'NaN'
        )
        .map((item) => item.iplMin)
    );
  }
  get referenciaIpl() {
    function average(nums) {
      if (nums == undefined) {
        return 0;
      }
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average([this.mediaIPLMax, this.mediaIPLMin]);
  }
  get mediaYieldMax() {
    function average(nums) {
      if (nums == undefined) {
        return 0;
      }
      if (nums.length == 0) {
        return 0;
      }
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      this.data.cotacao.meses

        .filter((item, i) => filterName('yieldMax', this.ignoreField.value, i))
        .filter(
          (item) =>
            item.yieldMax != undefined && item.yieldMax.toString() != 'NaN'
        )
        .map((item) => item.yieldMax)
    );
  }
  get mediaYieldMin() {
    function average(nums) {
      if (nums == undefined) {
        return 0;
      }
      if (nums.length == 0) {
        return 0;
      }
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      this.data.cotacao.meses

        .filter((item, i) => filterName('yieldMin', this.ignoreField.value, i))
        .filter(
          (item) =>
            item.yieldMin != undefined && item.yieldMin.toString() != 'NaN'
        )
        .map((item) => item.yieldMin)
    );
  }
  get referenciaYield() {
    function average(nums) {
      if (nums == undefined) {
        return 0;
      }
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average([this.mediaYieldMax, this.mediaYieldMin]);
  }
  get lastYield() {
    return (
      ((this.data.dpa[0].value *
        (this.data.cotacao.atual /
          this.data.cotacao.lpaAtual /
          this.data.cotacao.indicePrecoLucroAno)) /
        this.data.cotacao.atual) *
      100
    );
  }
  get mediaLpa() {
    function average(nums) {
      if (nums == undefined) {
        return 0;
      }
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      this.data.lpa
        .filter((item, i) => filterName('lpa', this.ignoreField.value, i))
        .filter((item) => item.value != undefined)
        .map((item) => item.value)
    );
  }
  get mediaLpaCrescimento() {
    function average(nums) {
      if (nums == undefined) {
        return 0;
      }
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      this.data.lpa
        .filter((item, i) =>
          filterName('lpaCrescimento', this.ignoreField.value, i)
        )
        .filter((item) => item.crescimento != undefined)
        .map((item) => item.crescimento)
    );
  }
  get mediaDpa() {
    function average(nums) {
      if (nums == undefined) {
        return 0;
      }
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      this.data.dpa
        .filter((item, i) =>
          filterName('dpaCrescimento', this.ignoreField.value, i)
        )
        .filter((item) => item.crescimento != undefined)
        .map((item) => item.crescimento)
    );
  }
  mediaCalc(list) {
    function average(nums) {
      if (nums == undefined) {
        return 0;
      }
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      list.filter((item) => item.value != undefined).map((item) => item.value)
    );
  }
  get mediaPayout() {
    function average(nums) {
      if (nums == undefined) {
        return 0;
      }
      if (nums.length == 0) {
        return 0;
      }
      return nums.reduce((a, b) => a + b, 0) / nums.length;
    }
    return average(
      this.data.cotacao.meses
        .filter((item, i) => filterName('payout', this.ignoreField.value, i))

        .filter(
          (item) => item.payout != undefined && item.payout.toString() != 'NaN'
        )
        .map((item) => item.payout)
    );
  }
  finaliza() {
    this.finalizado = true;
    console.log('analise', this.data);
    let cotacaoProjetada2020 =
      this.estimativaData.cotacao[this.estimativaData.cotacao.length - 1].max *
      1.2;
    console.log('cotacaoProjetda2020', cotacaoProjetada2020);
    let cotacaoComprarMax =
      (cotacaoProjetada2020 - this.data.cotacao.ultimos12Meses.min * 0.8) / 3 +
      this.data.cotacao.ultimos12Meses.min * 0.8;
    let cotacaoNeutraMax =
      (cotacaoProjetada2020 - cotacaoComprarMax) / 3 + cotacaoComprarMax;
    this.analise = {
      date: new Date(),
      comprarAcoes: {
        min: this.data.cotacao.ultimos12Meses.min * 0.8,
        max: cotacaoComprarMax,
      },
      posicaoNeutra: {
        min: cotacaoComprarMax,
        max: cotacaoNeutraMax,
      },
      venderAcoes: {
        min: cotacaoNeutraMax,
        max: cotacaoProjetada2020,
      },
    };
    this.analise.indiceED =
      (this.analise.venderAcoes.max - this.data.cotacao.atual) /
      (this.data.cotacao.atual - this.analise.comprarAcoes.min);

    let rentabilidadeCotacao =
      (this.analise.venderAcoes.max - this.data.cotacao.atual) /
      this.data.cotacao.atual;
    console.log(this.estimativaData.mediaDpaProjetada);
    let rentabilidadeProventos =
      this.estimativaData.mediaDpaProjetada / this.data.cotacao.atual;
    console.log('retabilidadECotacao', rentabilidadeCotacao);
    console.log('rentabilidadeProventos', rentabilidadeProventos);

    // =((rentabilidadeCotacao/5+rentabilidadeProventos)*5+1)^(1/5)-1

    const start = (rentabilidadeCotacao / 5 + rentabilidadeProventos) * 5 + 1;
    console.log(start);
    function checkSquareRoot(x, y) {
      if (x > 0) {
        return Math.pow(x, y);
      }
      return -1 * Math.pow(-x, y);
    }
    const gpa = checkSquareRoot(start, 0.2) - 1;
    console.log(gpa);
    this.analise.ganhoPotencial = gpa * 100;

    this.setAction();
    this.acaoService
      .saveAnalise(
        this.data,
        this.analise,
        this.analiseRetrospectiva.value,
        this.estimativasPorcentagem.value,
        this.ignoreField.value,
      )
      .subscribe(() => {
        console.log('Analise Salva');
      });
  }
  setAction() {
    if (this.data.cotacao.atual < this.analise.comprarAcoes.max) {
      this.analise.action = 'Comprar Ações';
      return;
    }

    if (this.data.cotacao.atual < this.analise.posicaoNeutra.max) {
      this.analise.action = 'Posição Neutra';
      return;
    }

    if (this.data.cotacao.atual > this.analise.venderAcoes.min) {
      this.analise.action = 'Vender Ações';
    }
    this.analise.action = 'Vender Ações';
  }
  getTooltipPorte(porte: string) {
    if (porte == undefined) {
      return 'n/d';
    }
    if (porte.toLowerCase() == 'micro cap')
      return `Capitalização de mercado de menos de US$ 300 milhões.`;
    if (porte.toLowerCase() == 'small cap')
      return `Capitalização de 300 milhões de dólares até US$ 2 bilhões.`;

    if (porte.toLowerCase() == 'mid cap')
      return `Capitalização de US$ 2 bilhões a US$ 10 bilhões.`;

    if (porte.toLowerCase() == 'large cap')
      return `Capitalização de mercado de 10 bilhões de dólares ou mais.`;
    return 'Capitalização de mercado de 10 bilhões de dólares ou mais.';
  }
  refreshLink(link) {
    location.href = link.replace('//', '/');
    console.log(link);
  }
  toogleField(name, i, event) {
    console.log(event);
    let value = this.ignoreField.value;
    if (value[name] == undefined) {
      value[name] = {};
    }
    if (value[name][i] == undefined) {
      value[name][i] = true;
    } else {
      value[name][i] = !value[name][i];
    }
    if (value[name][i] == true) {
      event.toElement.style.textDecoration = 'line-through';
    }
    if (value[name][i] == false) {
      event.toElement.style.textDecoration = '';
    }
    this.ignoreField.setValue(value);
  }
  scrollTop() {
    document
      .getElementsByClassName('mat-drawer-content')[0]
      .scrollTo({ top: 0 });
  }
  print() {
    window.print();
  }
  refazer() {
    this.acaoService.excluiAnalise(this.data.ticker).subscribe(() => {
      this.finalizado = false;
    });
  }
}

<div class="acoes-list">
  <div class="acao-item">
    <div class="acao-heading">
      <div class="acao-heading-left">
        <div
          class="acao-logo"
          style="margin-bottom: 12px"
          [ngStyle]="{
            'background-image': 'url(/assets/empresas/' + acao.ticker + '.png)'
          }"
        ></div>
      </div>
      <div
        style="padding-right: 12px; font-weight: bold"
        class="acao-heading-left acao-text-ticker"
      >
        {{ acao.ticker }}
      </div>
      <div
        *ngIf="acao.uc == '1'"
        class="acao-heading-right acao-text-participacao"
      >
        <img
          [matTooltip]="'Empresa aprovada nos critérios Empreender Dinheiro.'"
          src="/assets/images/image.svg"
          style="width: 16px"
        />
      </div>
    </div>
    <div class="acao-heading">
      <div class="acao-heading-left acao-text-name">
        {{ acao.name }}
      </div>
      <div
        *ngIf="analisados.includes(acao.ticker)"
        class="acao-heading-right"
        style="
          font-size: 10px;
          font-weight: bold;
          color: green;
          text-transform: uppercase;
        "
      >
        Já analisado
      </div>
    </div>
    <div class="acao-content" style="margin-right: -16px">
      <div class="acao-content-item acao-text-cotacao-atual">
        R$ {{ acao.cotacao.atual | normalMoney }}
      </div>
      <div
        class="acao-content-item"
        style="
          text-align: center;
          font-size: 16px;
          text-align: right;
          padding-right: 16px;
        "
      >
        {{ acao.porte }}
      </div>
    </div>
    <div class="acao-content" style="margin-left: -16px; margin-right: -16px">
      <div class="acao-content-item">
        <div class="acao-content-item-title">
          {{ acao.empreenderDinheiro.governanca }}
        </div>
        <div class="acao-content-item-subtitle">Governança</div>
      </div>
      <div class="acao-content-item">
        <div class="acao-content-item-title">
          {{ acao.empreenderDinheiro.tagAlong }}
        </div>
        <div class="acao-content-item-subtitle">TAG. ALONG</div>
      </div>
      <div class="acao-content-item">
        <div
          class="acao-content-item-title"
          style="text-transform: uppercase; font-size: 9px"
          [ngClass]="{
            dividaVerde:
              acao.empreenderDinheiro.divida.toLowerCase() ==
                'caixa maior que a dívida' ||
              acao.empreenderDinheiro.divida.toLowerCase() ==
                'dívida sob controle' ||
              acao.empreenderDinheiro.divida == 'Baixo Endividamento',
            dividaVeremlho:
              acao.empreenderDinheiro.divida == 'Alto Endividamento' ||
              acao.empreenderDinheiro.divida == 'Patrimônio Líq. Negativo'
          }"
        >
          {{ acao.empreenderDinheiro.divida | divida }}
        </div>
        <div class="acao-content-item-subtitle">Dívida</div>
      </div>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from './callback/callback.component';
import { ComparativoComponent } from './comparativo/comparativo.component';
import { DefaultComponent } from './layouts/default/default.component';
import { AcaoComponent } from './modules/acao/acao.component';
import { AdminUsersComponent } from './modules/admin-users/admin-users.component';
import { BuscarAcoesComponent } from './modules/buscar-acoes/buscar-acoes.component';
import { CadastroComponent } from './modules/cadastro/cadastro.component';
import { LoginComponent } from './modules/login/login.component';
import { RecoveryPasswordComponent } from './modules/recovery-password/recovery-password.component';
import { UploadPlanilhaComponent } from './modules/upload-planilha/upload-planilha.component';
import { CallbackFolderComponent } from './callback-folder/callback-folder.component';
import { AuthGuard } from './shared/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: DefaultComponent,
    canActivate: [AuthGuard],
    children: [
      {
        redirectTo: '/buscar-acoes',
        path: '',
        pathMatch: 'full',
      },
      {
        path: 'buscar-acoes',
        component: BuscarAcoesComponent,
      },
      {
        path: 'login',
        component: DefaultComponent
      },
      {
        path: 'acao/:ticker',
        component: AcaoComponent,
      },

      {
        path: 'comparativo',
        component: ComparativoComponent,
      },

      {
        path: 'users',
        component: AdminUsersComponent,
      },
      {
        path: 'planilha',
        component: UploadPlanilhaComponent,
      },
    ],
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: 'folder-token/callback/:token',
    component: CallbackFolderComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
